import React, { useContext } from "react";
import DataContext from "../context/DataContext";
import { DICT } from "../Dictionary";
import { IconChevronLeft } from "@tabler/icons";

export default function BtnBack({ onClick, mode }) {

  const {
    appVal: { LANG },
  } = useContext(DataContext);

  return (
    <button
      onClick={onClick}
      className={`btn btn-text-icon group transition
      ${mode === "light" && "text-support-deep hover:text-support-dark"}
      ${mode === "dark" && "text-white hover:text-white"}`}
    >
      <span
        className={`icon flex items-center justify-center w-7 h-7 sm:w-8 sm:h-8 rounded-full mr-2 transition
        ${mode === "light" && "bg-support-normal group-hover:bg-support-deep"}
        ${mode === "dark" && "bg-support-deep group-hover:bg-support-dark"}`}
      >
        <IconChevronLeft
          size={22}
          stroke={4}
          strokeLinejoin="round"
          className="text-white"
        />
      </span>
      <span>{DICT.BTN_BACK[LANG]}</span>
    </button>
  );
}

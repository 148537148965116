import React, {useState, useRef} from 'react'
import { IconChevronUp, IconChevronDown } from "@tabler/icons";

export default function TimePicker({ value, onChange, setData, maxNum, showZero, loop }) {

  const [tempNum, setTempNum] = useState("");
  const timerRef = useRef(null);
  const timerIntRef = useRef(null);

  const convertedData = (valNum) => {
    const value = parseInt(valNum);
    if (value >= maxNum) {
      return maxNum;
    } else if (value >= 10) {
      return value;
    } else if (value > 0) {
      if (showZero) {
        return `0${value}`;
      } else {
        return value;
      }
    } else if (value === 0) {
      if (showZero) {
        return "00";
      } else {
        return "0";
      }
    }
  };
  
  const onBlur = () => {
    if (value) {
      setData(convertedData(value));
    } else {
      if (showZero) {
        setData(tempNum || "00");
      } else {
        setData(tempNum || "0");
      }
    }
  };

  const onFocus = () => {
    setTempNum(value);
    setData("");
  };
  

  const increment = () => {
    setData((count) => {
      const c = parseInt(count);
      if (c >= maxNum) {
        return convertedData(loop ? 0 : maxNum);
      } else if (c < maxNum) {
        return convertedData(c + 1);
      } else {
        return convertedData(c);
      }
    });
  };

  const decrement = () => {
    setData((count) => {
      const c = parseInt(count);
      if (c > maxNum) {
        return convertedData(loop ? 0 : maxNum);
      } else if (c > 0) {
        return convertedData(c - 1);
      } else {
        return convertedData(loop ? maxNum : c);
      }
    });
  };

  const doIncrement = () => {
    if (value || value !== "") {
      timerRef.current = setTimeout(() => {
        timerIntRef.current = setInterval(() => {
          increment();
        }, 80);
      }, 400);
    }
  };

  const doDecrement = () => {
    if (value || value !== "") {
      timerRef.current = setTimeout(() => {
        timerIntRef.current = setInterval(() => {
          decrement();
        }, 80);
      }, 400);
    }
  };

  const stopInput = (e) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (timerIntRef.current) {
      clearInterval(timerIntRef.current);
    }
  };


  return (
    <div className="text-center p-3">
      <button
        className="block mx-auto w-16 bg-transparent text-main-normal hover:text-main-dark hover:bg-main-bg rounded-md select-none"
        tabIndex="-1"
        onClick={() => increment(value)}
        onMouseDown={() => doIncrement(value)}
        onMouseUp={() => stopInput(value)}
        onMouseLeave={() => stopInput(value)}
        onTouchStart={() => doIncrement(value)}
        onTouchEnd={() => stopInput(value)}
      >
        <IconChevronUp
          size={32}
          stroke={3}
          strokeLinejoin="round"
          className="mx-auto"
        />
      </button>
      <input
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        type="tel"
        value={value}
        placeholder={value || tempNum}
        className="text-center text-support-dark border-2 hover:border-main-light text-3xl block w-full my-2 py-2 outline-none leading-none transition rounded-md placeholder:text-main-light"
        maxLength={2}
      />
      <button
        className="block mx-auto w-16 bg-transparent text-main-normal hover:text-main-dark hover:bg-main-bg rounded-md select-none"
        tabIndex="-1"
        onClick={() => decrement(value)}
        onMouseDown={() => doDecrement(value)}
        onMouseUp={() => stopInput(value)}
        onMouseLeave={() => stopInput(value)}
        onTouchStart={() => doDecrement(value)}
        onTouchEnd={() => stopInput(value)}
      >
        <IconChevronDown
          size={32}
          stroke={3}
          strokeLinejoin="round"
          className="mx-auto"
        />
      </button>
    </div>
  );
}

import React from "react";

export default function BtnChart({ id, changeChart, selected, url }) {
  return (
    <button
      type="button"
      onClick={() => {
        if (id !== selected) {
          changeChart(id);
        }
      }}
      className={`flex rounded-lg justify-center items-center w-8 h-8 lg:w-10 lg:h-10 ml-2 mr:0 md:ml-1 md:mr-1 lg:mx-1 bg-white border-2 ${
        id === selected
          ? "border-support-normal"
          : "border-transparent hover:border-main-light"
      }`}
    >
      {id === selected ? (
        <img
          src={`${url}_active.svg`}
          className="block lg:w-7 lg:h-7"
          alt="Chart"
          width="auto"
          height="auto"
        />
      ) : (
        <img
          src={`${url}.svg`}
          className="block lg:w-7 lg:h-7"
          alt="Chart"
          width="auto"
          height="auto"
        />
      )}
    </button>
  );
}

import React, { useContext, useState, useEffect, useRef } from "react";
import DataContext from "../../context/DataContext";
import { DICT } from "../../Dictionary";
import checkUserData from "../../utils/checkUserData";

import { useNavigate } from "react-router-dom";
import BtnBack from "../../components/BtnBack";
import ScrollHeight from "../../components/ScrollHeight";
import { IconCheck } from "@tabler/icons";

export default function DeathYear() {
  const {
    appVal: { LANG, USER },
    setAppVal,
  } = useContext(DataContext);

  const usrBirth = {
    year: USER?.birth?.year || null,
    month: USER?.birth?.month ?? null,
    day: USER?.birth?.day || null,
  };

  const [yearSelected, setYearSelected] = useState(USER?.death?.year || null);

  const navigate = useNavigate();
  const navRef = useRef(navigate);

  const year = new Date().getFullYear();
  const years = Array.from(new Array(120), (val, index) => year + index);

  useEffect(() => {
    checkUserData(
      navRef,
      USER.birth && USER.death
    );
    return () => {};
  }, [USER.birth, USER.death]);

  const selectYear = (year) => {
    setYearSelected(year);
  };

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navRef.current(-1);
    } else {
      navRef.current("/", { replace: true });
    }
  };

  const goNext = () => {
    navRef.current("/fill/month");
    setAppVal((v) => ({
      ...v,
      USER: {
        ...v.USER,
        death: { ...v.USER.death, year: yearSelected, month: null },
      },
    }));
  };

  return (
    <div
      className={`flex flex-col absolute md:relative w-full h-full md:p-6 iconic overflow-hidden`}
    >
      <div className="view-top fix-top md:p-0 md:pb-4 p-4 z-10">
        <div className="flex items-center text-lg sm:text-xl font-semibold text-white">
          <BtnBack onClick={goBack} mode="dark" />
        </div>
      </div>
      <div className="view-middle view-middle-dark flex flex-col flex-1 md:overflow-visible relative">
        <ScrollHeight hasBottom={true}>
          {/* Title */}
          <div className="box text-left md:text-center px-4 md:px-0 mb-2 sm:mb-4">
            <h1 className="text-4xl leading-none sm:text-5xl font-bold text-support-dark mb-2">
              {DICT.LABEL_SELECTYEARDEATH[LANG]}
            </h1>
            <p className="text-xl sm:text-2xl text-main-dark">
              {DICT.LABEL_WHENYEARDEATH[LANG]}
            </p>
          </div>

          <div
            className="box-scroll px-4 md:px-0 w-full h-full justify-start md:max-h-cal-inside overflow-auto pt-2"
            style={{
              WebkitMaskImage:
                "linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 98%, transparent 100%)",
            }}
          >
            <div className="max-w-lg mx-auto md:text-xl text-lg">
              {years
                .filter((year) => {
                  return year - usrBirth.year <= 120;
                })
                .map((year, index) => {
                  return (
                    <button
                      onClick={() => {
                        selectYear(year);
                      }}
                      className={`btn btn-fill group mb-2 ${
                        yearSelected === year
                          ? "active bg-support-normal text-white"
                          : "hover:bg-natural-light hover:bg-opacity-50"
                      }`}
                      key={index}
                    >
                      <div>
                        <span className="inline-block pr-3 text-right text-natural-dark font-semibold">
                          {DICT.LABEL_YEARUNIT[LANG]}
                          {LANG === "TH" && ` ${Number(year) + 543}`}
                          {LANG === "EN" && year}
                        </span>
                        <span className="inline-block pl-3 text-left text-main-dark">
                          {DICT.LABEL_AGE[LANG]} {year - usrBirth.year}{" "}
                          {DICT.LABEL_YEARS[LANG]}
                        </span>
                      </div>
                      {yearSelected === year && (
                        <IconCheck
                          size={22}
                          stroke={4}
                          strokeLinejoin="round"
                          className={`absolute right-0 mr-3 text-white`}
                        />
                      )}
                    </button>
                  );
                })}
            </div>
          </div>
        </ScrollHeight>
      </div>
      <div className="view-bottom bg-white md:rounded-b-2xl p-4 md:p-6 pt-4 md:pt-6">
        <div className="flex flex-col justify-center mx-auto w-full max-w-lg md:w-64">
          <button
            onClick={goNext}
            className={`btn btn-primary ${!yearSelected && "disabled"}`}
            disabled={!yearSelected}
          >
            {DICT.BTN_NEXT[LANG]}
          </button>
        </div>
      </div>
    </div>
  );
}

import React from 'react'

export default function ScrollHeight({children, hasBottom}) {
  return (
    <div className="absolute-middle absolute md:relative w-full h-full">
      <div
        className={`inside-middle ${
          hasBottom ? "pt-4 md:p-0 md:pb-0 h-full" : "p-4 md:p-0"
        } `}
      >
        {children}
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import bgMode from "../../utils/bgMode";

export default function Death() {
  useEffect(() => {
    bgMode("dark");
    return () => {};
  }, []);
  return (
    <><Outlet /></>
  )
}

import React, { useContext, useEffect, useRef } from "react";
import { format, getDaysInMonth } from "date-fns";
import { th } from "date-fns/locale";
import bgMode from "../../utils/bgMode";

import DataContext from "../../context/DataContext";
import { DICT } from "../../Dictionary";

import { useNavigate, Link } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import BtnBack from "../../components/BtnBack";
import ScrollHeight from "../../components/ScrollHeight";

export default function DateOfBirth() {

  const {
    appVal: { LANG, USER },
    setAppVal,
  } = useContext(DataContext);

  const usrBirth = {
    year: USER?.birth?.year || null,
    month: USER?.birth?.month ?? null,
    // month: USER?.birth?.month === 0 ? 0 : USER?.birth?.month ? USER?.birth?.month : null,
    day: USER?.birth?.day || null,
  };

  const navigate = useNavigate();
  const navRef = useRef(navigate);

  const year = new Date().getFullYear();
  const years = Array.from(new Array(120), (val, index) => year - index - 1);

  const months = Array.from(new Array(12), (val, index) => 0 + index);
  const getMonthName = (month, lang) => {
    if (lang === "en") {
      return format(new Date(2000, month, 1), "MMMM");
    }
    if (lang === "th") {
      return format(new Date(2000, month, 1), "MMMM", { locale: th });
    }
  }

  const maxDaysInMonth = USER?.birth ? getDaysInMonth(new Date(usrBirth.year, usrBirth.month)) : 31;
  const days = Array.from(
    new Array(maxDaysInMonth),
    (val, index) => 1 + index
  );

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navRef.current(-1);
    } else {
      navRef.current("/", { replace: true });
    }
  };

  useEffect(() => {
    bgMode("light");
    // Auto-Select max-day count from month
    if (usrBirth.day > maxDaysInMonth) {
      setAppVal((v) => ({
        ...v,
        USER: {
          ...v.USER,
          birth: { ...v.USER.birth, day: maxDaysInMonth },
        },
      }));
    }
    return () => {};
  }, [setAppVal, usrBirth.day, maxDaysInMonth]);
  

  return (
    <div
      className={`flex flex-col absolute md:relative w-full h-full md:p-6 iconic`}
    >
      <div className="view-top fix-top md:p-0 md:pb-4 p-4 z-10">
        <div className="flex items-center text-lg sm:text-xl font-semibold text-support-deep">
          <BtnBack onClick={goBack} mode="light" />
        </div>
      </div>
      <div className="view-middle view-middle-light flex flex-col flex-1 overflow-y-scroll md:overflow-visible relative">
        <ScrollHeight hasBottom={false}>
          
          {/* Title */}
          <div className="box text-left md:text-center mb-6">
            <h1 className="text-4xl leading-none sm:text-5xl font-bold text-support-dark mb-2">
              {DICT.LABEL_SELECTDATE[LANG]}
            </h1>
            <p className="text-xl sm:text-2xl text-main-dark">
              {DICT.LABEL_WHENBORN[LANG]}
            </p>
          </div>

          <div className="box w-full max-w-lg mx-auto md:text-xl text-lg">
            {/* Year */}
            <div className="box-year mb-3">
              <div className="flex flex-row items-center text-right text-support-normal">
                <h3 className="w-24 sm:w-28 pr-3 font-semibold">
                  {DICT.LABEL_YEAR[LANG]}
                </h3>
                <div className="flex flex-col w-full md:text-lg text-md">
                  <Dropdown
                    selected={
                      usrBirth.year ? (
                        (LANG === "TH" &&
                          `${DICT.LABEL_YEARUNIT[LANG]} ${
                            Number(usrBirth.year) + 543
                          }`) ||
                        (LANG === "EN" && `${Number(usrBirth.year)}`)
                      ) : (
                        <span className="text-natural-normal">
                          {DICT.LABEL_BYEAR[LANG]}
                        </span>
                      )
                    }
                    values={years.map((item) => {
                      return {
                        id: item,
                        label:
                          (LANG === "TH" &&
                            `${DICT.LABEL_YEARUNIT[LANG]} ${
                              Number(item) + 543
                            }`) ||
                          (LANG === "EN" && `${item}`),
                        onClick: () => {
                          setAppVal((v) => ({
                            ...v,
                            USER: {
                              ...v.USER,
                              birth: { ...v.USER.birth, year: item },
                            },
                          }));
                        },
                      };
                    })}
                  />
                </div>
              </div>
            </div>

            {/* Month */}
            <div className="box-month mb-3">
              <div className="flex flex-row items-center text-right text-support-normal">
                <h3 className="w-24 sm:w-28 pr-3 font-semibold">
                  {DICT.LABEL_MONTH[LANG]}
                </h3>
                <div className="flex flex-col w-full md:text-lg text-md">
                  <Dropdown
                    selected={
                      usrBirth.month || usrBirth.month === 0 ? (
                        (LANG === "TH" &&
                          `${getMonthName(usrBirth.month, "th")}`) ||
                        (LANG === "EN" &&
                          `${getMonthName(usrBirth.month, "en")}`)
                      ) : (
                        <span className="text-natural-normal">
                          {DICT.LABEL_BMONTH[LANG]}
                        </span>
                      )
                    }
                    values={months.map((item) => {
                      return {
                        id: item,
                        label:
                          (LANG === "TH" && `${getMonthName(item, "th")}`) ||
                          (LANG === "EN" && `${getMonthName(item, "en")}`),
                        onClick: () => {
                          setAppVal((v) => ({
                            ...v,
                            USER: {
                              ...v.USER,
                              birth: { ...v.USER.birth, month: item },
                            },
                          }));
                        },
                      };
                    })}
                  />
                </div>
              </div>
            </div>

            {/* Day */}
            <div className="box-day mb-3">
              <div className="flex flex-row items-center text-right text-support-normal">
                <h3 className="w-24 sm:w-28 pr-3 font-semibold">
                  {DICT.LABEL_BDAY[LANG]}
                </h3>
                <div className="flex flex-col w-full md:text-lg text-md">
                  <Dropdown
                    selected={
                      usrBirth.day ? (
                        usrBirth.day < maxDaysInMonth ? (
                          usrBirth.day
                        ) : (
                          maxDaysInMonth
                        )
                      ) : (
                        <span className="text-natural-normal">
                          {DICT.LABEL_BDAY[LANG]}
                        </span>
                      )
                    }
                    values={days.map((item) => {
                      return {
                        id: item,
                        label: item,
                        onClick: () => {
                          setAppVal((v) => ({
                            ...v,
                            USER: {
                              ...v.USER,
                              birth: { ...v.USER.birth, day: item },
                            },
                          }));
                        },
                      };
                    })}
                    scrollHeight="max-h-44 md:max-h-48"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="box">
            <div className="flex flex-col justify-center mt-4 pb-4 mx-auto w-full max-w-lg md:w-64">
              <Link to={"/intro"}>
                <button
                  className={`btn btn-primary ${
                    !(
                      usrBirth.day &&
                      (usrBirth.month || usrBirth.month === 0) &&
                      usrBirth.year
                    ) && "disabled"
                  }`}
                  disabled={
                    !(
                      usrBirth.day &&
                      (usrBirth.month || usrBirth.month === 0) &&
                      usrBirth.year
                    )
                  }
                >
                  {DICT.BTN_NEXT[LANG]}
                </button>
              </Link>
            </div>
          </div>
        </ScrollHeight>
      </div>
    </div>
  );
}

import { createContext } from "react";

const DataContext = createContext({
  LANG: "TH",
  USER: {
    birth: {
      year: "",
      month: "",
      day: "",
    },
  },
});

export default DataContext;

import { apiUrl, keyApi, sheetId } from "../keyApi";

const fetchApi = async (sheetName) => {
  const fetchData = async () => {
    const res = await fetch(`${apiUrl}${sheetName}`, {
      headers: {
        Authorization: `Bearer ${keyApi}`,
        "X-Spreadsheet-Id": sheetId,
      },
    });
    return res.json();
  };
  const response = await fetchData();

  if (response.results) {
    return {
      success: true,
      ...response,
    };
  } else {
    return {
      success: false,
      ...response,
    };
  }
};

export default fetchApi;

export const DICT = {
  LANG_CHOOSE: {
    TH: `เลือกภาษา`,
    EN: `Language`,
  },
  LANG_LABEL: {
    TH: `ไทย`,
    EN: `English`,
  },
  HOME_P_APPDESC: {
    TH: `โปรแกรมที่กระตุ้นให้เราตระหนักว่าเรามีเวลาในชีวิตจำกัด จึงควรเร่งทำสิ่งสำคัญด้วยความไม่ประมาท ก่อนเวลาจะหมดลง`,
    EN: `This app will encourage us to realize that we have limited time in life. Therefore, it is imperative to do important things without negligence before the time runs out.`,
  },
  BTN_GETSTARTED: {
    TH: `เริ่มต้น`,
    EN: `Get Started`,
  },
  BTN_SUPPORTUS: {
    TH: `สนับสนุน`,
    EN: `Support Us`,
  },
  BTN_ABOUTUS: {
    TH: `เกี่ยวกับเรา`,
    EN: `About Us`,
  },
  BTN_BACK: {
    TH: `กลับ`,
    EN: `Back`,
  },
  BTN_NEXT: {
    TH: `ต่อไป`,
    EN: `Next`,
  },
  BTN_OK: {
    TH: `ตกลง`,
    EN: `OK`,
  },
  BTN_CANCEL: {
    TH: `ยกเลิก`,
    EN: `Cancel`,
  },
  BTN_VIEWRESULT: {
    TH: `ดูผลลัพธ์`,
    EN: `Show Results`,
  },
  BTN_SAVE: {
    TH: `บันทึกรูป`,
    EN: `Save Image`,
  },
  BTN_CLOSE: {
    TH: `ปิด`,
    EN: `Close`,
  },
  BTN_SHARE: {
    TH: `แชร์`,
    EN: `Share`,
  },
  BTN_STARTAGAIN: {
    TH: `เริ่มใหม่`,
    EN: `Restart`,
  },
  LABEL_DAY: {
    TH: `วัน`,
    EN: `Day`,
  },
  LABEL_MONTH: {
    TH: `เดือน`,
    EN: `Month`,
  },
  LABEL_YEAR: {
    TH: `ปี`,
    EN: `Year`,
  },
  LABEL_BDAY: {
    TH: `วันเกิด`,
    EN: `Day`,
  },
  LABEL_BMONTH: {
    TH: `เดือนเกิด`,
    EN: `Month`,
  },
  LABEL_BYEAR: {
    TH: `ปีเกิด`,
    EN: `Year`,
  },
  LABEL_SELECTDATE: {
    TH: `ระบุวันเกิด`,
    EN: `Select Date`,
  },
  LABEL_WHENBORN: {
    TH: `คุณเกิดวันใด?`,
    EN: `When were you born?`,
  },
  LABEL_SELECTYEARDEATH: {
    TH: `ปีตาย`,
    EN: `Year of Death`,
  },
  LABEL_WHENYEARDEATH: {
    TH: `คุณคิดว่าคุณจะตายปีใด?`,
    EN: `Which year do you think you might die?`,
  },
  LABEL_SELECTMONTHDEATH: {
    TH: `เดือนตาย`,
    EN: `Month of Death`,
  },
  LABEL_WHENMONTHDEATH: {
    TH: `คุณคิดว่าคุณจะตายเดือนใด?`,
    EN: `Which month do you think you might die?`,
  },
  LABEL_SELECTDAYDEATH: {
    TH: `วันตาย`,
    EN: `Day of Death`,
  },
  LABEL_WHENDAYDEATH: {
    TH: `คุณคิดว่าคุณจะตายวันใด?`,
    EN: `Which date do you think you might die?`,
  },
  LABEL_SELECTTIMEDEATH: {
    TH: `เวลาตาย`,
    EN: `Time of Death`,
  },
  LABEL_WHENTIMEDEATH: {
    TH: `คุณคิดว่าคุณจะตายเวลาใด?`,
    EN: `What time will you die?`,
  },
  LABEL_YEARUNIT: {
    TH: `พ.ศ.`,
    EN: ``,
  },
  LABEL_YEARS: {
    TH: `ปี`,
    EN: ``,
  },
  LABEL_AGE: {
    TH: `อายุ`,
    EN: `Age`,
  },
  LABEL_TIMELEFT: {
    TH: `คุณเหลือเวลาอีก`,
    EN: `You have time left`,
  },
  LABEL_LIFELEFT: {
    TH: `ฉันมีชีวิตเหลืออีก`,
    EN: `Rest of life`,
  },
  LABEL_NOTIMELEFT: {
    TH: `คุณเสียชีวิตแล้ว`,
    EN: `You are Dead`,
  },
  LABEL_UNITDAY: {
    TH: `วัน`,
    EN: `days`,
  },
  LABEL_UNITHOUR: {
    TH: `ชั่วโมง`,
    EN: `Hours`,
  },
  LABEL_UNITMINUTE: {
    TH: `นาที`,
    EN: `Min`,
  },
  LABEL_UNITSECOND: {
    TH: `วินาที`,
    EN: `Sec`,
  },
  LABEL_PASS: {
    TH: `ผ่านไปแล้ว`,
    EN: `Passed`,
  },
  LABEL_BORN: {
    TH: `เกิด`,
    EN: `Born`,
  },
  LABEL_DEATH: {
    TH: `ตาย`,
    EN: `Death`,
  },
  LABEL_SHARE: {
    TH: `แชร์`,
    EN: `Share`,
  },
  LABEL_ADD: {
    TH: `เพิ่ม`,
    EN: `Add`,
  },
  LABEL_EDIT: {
    TH: `แก้ไข`,
    EN: `Edit`,
  },
  LABEL_TYPING: {
    TH: `พิมพ์`,
    EN: `Enter`,
  },
  LABEL_MSG: {
    TH: `ข้อความ`,
    EN: `Caption`,
  },
  LABEL_CHAR: {
    TH: `ตัวอักษร`,
    EN: `Character(s)`,
  },
  LABEL_NOTAVAILABLE: {
    TH: `ไม่พบเนื้อหา`,
    EN: `Content not available`,
  },
  ARR_TIMES: {
    TH: [
      { time: "00:00", label: "เที่ยงคืน" },
      { time: "03:00", label: "ตี 3" },
      { time: "06:00", label: "6 โมงเช้า" },
      { time: "09:00", label: "9 โมงเช้า" },
      { time: "12:00", label: "เที่ยงวัน" },
      { time: "15:00", label: "บ่าย 3" },
      { time: "18:00", label: "6 โมงเย็น" },
      { time: "21:00", label: "3 ทุ่ม" },
    ],
    EN: [
      { time: "00:00", label: "Midnight" },
      { time: "03:00", label: "3 AM" },
      { time: "06:00", label: "6 AM" },
      { time: "09:00", label: "9 AM" },
      { time: "12:00", label: "Midday" },
      { time: "15:00", label: "3 PM" },
      { time: "18:00", label: "6 PM" },
      { time: "21:00", label: "9 PM" },
    ],
  },
  LABEL_CUSTOMTIME: {
    TH: `กำหนดเวลาเอง`,
    EN: `Custom Time`,
  },
  MSG_HOMEINTRO: {
    TH: [
      "ต่อไปเว็บไซต์จะสอบถาม วัน-เดือน-ปี ที่คุณคาดว่าน่าจะเป็นเวลาตายของคุณ เพื่อประมาณเวลาที่เหลืออยู่บนโลกใบนี้",
      "โปรแกรมนี้ไม่ใช่โปรแกรมพยากรณ์ ดังนั้นเวลาในชีวิตของคุณอาจหมดลงก่อนหรือหลังจากนี้ก็ได้",
      <span className="font-semibold">
        หากคุณพร้อมแล้ว เรามาเริ่มนับเวลาถอยหลังชีวิตได้เลย
      </span>,
    ],
    EN: [
      "Next, the website will ask for the day-month-year that you expect to be the time of your death. to estimate the time remaining on this planet.",
      "This program is not a forecast program. So the time in your life may run out before or after this.",
      "Click the 'Next' to countdown your remaining days.",
    ],
  },
  MSG_RESULTINTRO1: {
    TH: "คุณกำหนดวัน-เวลาตาย ไว้ที่",
    EN: "You set the date and time of death at",
  },
  MSG_RESULTINTRO2: {
    TH: [
      "หน้าถัดไป คือประมาณเวลาชีวิตที่คุณมีเหลืออยู่",
      <span className="font-semibold">หายใจเข้าลึกๆ แล้วกดดูผลลัพธ์</span>,
    ],
    EN: [
      "The next page is an estimate of how much time you have left of your life.",
      <span className="font-semibold">
        Take a deep breath and see your results.
      </span>,
    ],
  },
  LANG_X: {
    TH: `ไทย`,
    EN: `English`,
  },
};

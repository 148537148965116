import React, { createElement, useContext, useEffect, useState, useRef } from "react";
import fetchApi from "../../utils/fetchApi";

import DataContext from "../../context/DataContext";
import { DICT } from "../../Dictionary";
import bgMode from "../../utils/bgMode";

import { useNavigate } from "react-router-dom";
import BtnBack from "../../components/BtnBack";
import Loader from "../../components/Loader";

export default function Donate() {

  const {
    appVal: { LANG },
  } = useContext(DataContext);
  const [data, setData] = useState(null);

  const navigate = useNavigate();
  const navRef = useRef(navigate);

  const goDone = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navRef.current(-1);
    } else {
      navRef.current("/me", { replace: true });
    }
  };

  useEffect(() => {
    bgMode("light");
    fetchApi("SupportUs").then((res) => {
      setData(res);
    });
  }, []);
  
  return (
    <div
      className={`flex flex-col absolute md:relative w-full h-full md:p-6 iconic`}
    >
      <div className="view-top shadow-md md:shadow-none fix-top md:p-0 md:pb-4 p-4 z-10">
        <div className="flex items-center text-lg sm:text-xl font-semibold text-support-deep">
          <BtnBack onClick={goDone} mode="light" />
        </div>
      </div>
      <div className="view-middle bg-main-bg md:bg-transparent bg-opacity-50 flex flex-col flex-1 overflow-y-scroll md:overflow-visible relative">
        <div className="absolute-middle absolute sm:relative w-full h-full">
          <div className="inside-middle p-4 md:p-0">
            <div className="box text-left w-full max-w-lg mx-auto my-2">
              <h1 className="text-4xl leading-none sm:text-5xl font-bold text-support-dark">
                {DICT.BTN_SUPPORTUS[LANG]}
              </h1>
            </div>
            <div className="box text-left leading-normal w-full max-w-lg mx-auto md:text-xl text-lg text-support-deep my-2">
              {data ? (
                data.success ? (
                  <>
                    {data.results.map((row, index) => {
                      return createElement(
                        row.tag,
                        {
                          key: index,
                          className: `block my-1 py-0.5 ${row.class}`,
                        },
                        <span
                          className="flex items-center"
                          dangerouslySetInnerHTML={{
                            __html: row[LANG],
                          }}
                        ></span>
                      );
                    })}
                    <div className="flex flex-col justify-center mt-6 mx-auto w-full max-w-lg md:w-64">
                      <button onClick={goDone} className={`btn btn-primary`}>
                        {DICT.BTN_OK[LANG]}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center py-16">
                      {DICT.LABEL_NOTAVAILABLE[LANG]}
                    </div>
                  </>
                )
              ) : (
                <div className="flex mb-16 mt-8 md:mt-16 justify-center items-center">
                  <Loader size={64} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function checkUserData(navRef, context) {

  const navigate = navRef.current;
  
  if (context === undefined || context === null) {
    navigate("/", { replace: true });
    return false;
  } else {
    return true;
  }
}

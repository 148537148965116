import React, { useRef, useContext, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { IconWorld, IconPencil } from "@tabler/icons";
import ProgressArch from "../../components/ProgressArch";
import ProgressBlock from "../../components/ProgressBlock";
import ProgressCandle from "../../components/ProgressCandle";
import { DICT } from "../../Dictionary";
import { exportAsImage, downloadImage } from "../../utils/exportAsImage";
import DataContext from "../../context/DataContext";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import ModalEditText from "./ModalEditText";

export default function ModalShare({
  chartType,
  bornToNow,
  bornToDead,
  distanceTimeDead,
  distanceDayDead,
  btnFocusRef,
  closeModal,
}) {

  const exportRef = useRef(null);
  const btnModalFocusRef = useRef();

  const [countUpdate, setCountUpdate] = useState(0);
  const [exportImgSource, setExportImgSource] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    image: null,
    caption: ""
  });
  

  const {
    appVal: { LANG },
  } = useContext(DataContext);

  const openModalShare = () => {
    setIsModalOpen(true);
  };

  const closeModalShare = () => {
    setIsModalOpen(false);
  };

  const submitModal = (data) => {
    closeModalShare();
    setCountUpdate(1); // reset count
    setTimeout(() => {
      setModalData(data);
    }, 100);
  };;

  useEffect(() => {
    const fetchData = async () => {
      const getImage = await exportAsImage(exportRef.current, "capture");
      if (getImage) {
        setCountUpdate((prev) => prev + 1);
        setExportImgSource(getImage);
      }
    };
    fetchData();
  }, [exportRef.current, modalData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="p-4 md:p-6">
      <div className="modal-header flex justify-between items-baseline mb-2">
        <h2 className="text-2xl leading-none sm:text-3xl font-bold text-support-dark">
          {DICT.LABEL_SHARE[LANG]}
        </h2>
        <button
          onClick={openModalShare}
          className="font-semibold text-lg text-support-dark hover:opacity-80"
        >
          {modalData.caption.length === 0 ? (
            <span>
              + {DICT.LABEL_ADD[LANG]}
              {LANG === "EN" && " "}
              {DICT.LABEL_MSG[LANG]}
            </span>
          ) : (
            <div className="relative flex justify-baseline">
              <span className="absolute left-0 ml-0.5">
                <IconPencil />
              </span>
              <span className="pl-7">{DICT.LABEL_EDIT[LANG]}</span>
            </div>
          )}
        </button>
      </div>

      {exportImgSource && countUpdate > 1 ? (
        <div>
          <img
            className="rounded-xl border border-main-light"
            src={exportImgSource}
            alt=""
          />
        </div>
      ) : (
        <div className="flex h-48 justify-center items-center">
          <Loader size={64} />
        </div>
      )}

      <div className="h-0 overflow-hidden">
        <div
          ref={exportRef}
          id="captureImg"
          className="flex flex-1 flex-col bg-main-bg"
          style={{ width: 480, height: 480 }}
        >
          <div className="h-full px-4 pt-3 pb-3">
            {/* No Caption */}

            <table
              className={modalData.caption === "" ? "table" : "hidden"}
              width="100%"
              height="100%"
            >
              <thead>
                <tr>
                  <th>
                    <div className="flex justify-between">
                      {distanceTimeDead ? (
                        <div className="text-support-dark header mb-2">
                          <h3 className="text-2xl font-semibold">
                            {DICT.LABEL_LIFELEFT[LANG]}
                          </h3>
                          <div className="text-5xl font-bold mb-1">
                            <span>
                              <NumberFormat
                                value={distanceDayDead}
                                displayType={"text"}
                                thousandSeparator={true}
                                allowNegative={false}
                              />
                            </span>
                            <span className="ml-2">
                              {DICT.LABEL_UNITDAY[LANG]}
                            </span>
                          </div>
                          <div className="text-lg">
                            <span>
                              {`${
                                // Hour
                                distanceTimeDead &&
                                distanceTimeDead?.hours !== 0
                                  ? `${distanceTimeDead?.hours} ${DICT.LABEL_UNITHOUR[LANG]} `
                                  : ``
                              }`}
                              {`${
                                // Min
                                distanceTimeDead &&
                                distanceTimeDead?.minutes !== 0
                                  ? `${distanceTimeDead?.minutes} ${DICT.LABEL_UNITMINUTE[LANG]} `
                                  : ``
                              }`}
                              {`${
                                // Sec
                                distanceTimeDead &&
                                distanceTimeDead?.seconds !== 0
                                  ? `${distanceTimeDead?.seconds} ${DICT.LABEL_UNITSECOND[LANG]}`
                                  : ``
                              }`}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="text-support-dark header">
                          <h3 className="text-4xl font-bold my-1">
                            {DICT.LABEL_NOTIMELEFT[LANG]}
                          </h3>
                        </div>
                      )}

                      <div className="flex flex-col uppercase text-center">
                        <span className="text-4xl text-main-normal tracking-wider leading-none">
                          Life
                        </span>
                        <span className="text-xs text-support-deep font-medium leading-none tracking-wide">
                          Countdown
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {chartType === 0 && (
                      <div className="flex justify-center items-center relative max-w-sm mx-auto -mb-3">
                        <ProgressArch
                          animation={false}
                          current={bornToNow}
                          full={bornToDead}
                        />
                      </div>
                    )}

                    {chartType === 1 && (
                      <div className="flex justify-center items-center relative mx-auto w-full h-full">
                        <ProgressBlock
                          current={bornToNow}
                          full={bornToDead}
                          desktopMode={false}
                        />
                      </div>
                    )}

                    {chartType === 2 && (
                      <div className="flex justify-center items-center relative mx-auto w-full h-full">
                        <ProgressCandle
                          current={bornToNow}
                          full={bornToDead}
                          desktopMode={false}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td>
                    <div className="flex h-10 justify-center items-center text-lg text-support-dark">
                      <IconWorld />
                      <span className="ml-1 -mt-0.5 font-semibold">
                        lifecountdown&#x2024;peacefuldeath&#x2024;co
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>

            {/* Caption */}

            <table
              className={modalData.caption !== "" ? "table" : "hidden"}
              width="100%"
              height="100%"
            >
              <thead>
                <tr>
                  <th>
                    <div className="flex justify-between">
                      <div className="flex flex-row justify-center items-center">
                        {modalData.image && (
                          <div
                            className="flex justify-center items-center relative w-20 h-20 mr-4 rounded-full overflow-hidden bg-cover bg-center bg-opacity-50"
                            style={{
                              backgroundImage: `url('${modalData.image.data}')`,
                            }}
                          >
                            <img
                              className="w-auto h-full max-w-none absolute z-20"
                              src={modalData.image.data}
                              alt=""
                            />
                            <div className="bg-black bg-opacity-20 w-full h-full z-10"></div>
                          </div>
                        )}
                        
                        {distanceTimeDead ? (
                          <div className="text-support-dark header mb-1">
                            <h3 className="text-xl font-semibold">
                              {DICT.LABEL_LIFELEFT[LANG]}
                            </h3>
                            <div className="text-4xl font-bold mb-0.5">
                              <span>
                                <NumberFormat
                                  value={distanceDayDead}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  allowNegative={false}
                                />
                              </span>
                              <span className="ml-2">
                                {DICT.LABEL_UNITDAY[LANG]}
                              </span>
                            </div>
                            <div className="text-sm font-semibold">
                              <span>
                                {`${
                                  // Hour
                                  distanceTimeDead &&
                                  distanceTimeDead?.hours !== 0
                                    ? `${distanceTimeDead?.hours} ${DICT.LABEL_UNITHOUR[LANG]} `
                                    : ``
                                }`}
                                {`${
                                  // Min
                                  distanceTimeDead &&
                                  distanceTimeDead?.minutes !== 0
                                    ? `${distanceTimeDead?.minutes} ${DICT.LABEL_UNITMINUTE[LANG]} `
                                    : ``
                                }`}
                                {`${
                                  // Sec
                                  distanceTimeDead &&
                                  distanceTimeDead?.seconds !== 0
                                    ? `${distanceTimeDead?.seconds} ${DICT.LABEL_UNITSECOND[LANG]}`
                                    : ``
                                }`}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="text-support-dark header">
                            <h3 className="text-3xl font-bold my-2">
                              {DICT.LABEL_NOTIMELEFT[LANG]}
                            </h3>
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col uppercase text-center">
                        <span className="text-4xl text-main-normal tracking-wider leading-none">
                          Life
                        </span>
                        <span className="text-xs text-support-deep font-medium leading-none tracking-wide">
                          Countdown
                        </span>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="flex flex-col h-full justify-around">
                      {chartType === 0 && (
                        <div className="flex justify-center items-center relative w-full max-w-xs mx-auto -mb-3">
                          <ProgressArch
                            animation={false}
                            current={bornToNow}
                            full={bornToDead}
                          />
                        </div>
                      )}

                      {chartType === 1 && (
                        <div className="flex justify-center items-center relative mx-auto w-full h-full scale-90 origin-top -mb-2">
                          <ProgressBlock
                            current={bornToNow}
                            full={bornToDead}
                            desktopMode={false}
                          />
                        </div>
                      )}

                      {chartType === 2 && (
                        <div className="flex justify-center items-center relative mx-auto w-full scale-90 origin-top -mb-2">
                          <ProgressCandle
                            current={bornToNow}
                            full={bornToDead}
                            desktopMode={false}
                          />
                        </div>
                      )}

                      <div className="relative -mt-2 flex justify-center items-center bg-natural-alpha pl-4 pr-4  rounded-xl text-support-deep">
                        <div className="absolute left-0 top-0 -ml-1 -mt-2 text-7xl text-support-main">
                          <img src="svg/ic_quote.svg" alt="" />
                        </div>
                        <div className="flex h-14 text-lg leading-tight overflow-hidden text-center justify-center items-center break-words">
                          {modalData.caption}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td>
                    <div className="flex h-10 justify-center items-center text-lg text-support-dark">
                      <IconWorld />
                      <span className="ml-1 -mt-0.5 font-semibold">
                        lifecountdown&#x2024;peacefuldeath&#x2024;co
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      <div className="flex flex-row-reverse mt-4 sm:px-4">
        <button
          type="button"
          onClick={async () => {
            downloadImage(exportImgSource, "capture");
          }}
          className={`btn btn-modal btn-blue ml-1`}
          ref={btnFocusRef}
        >
          {DICT.BTN_SAVE[LANG]}
        </button>
        <button
          type="button"
          onClick={closeModal}
          className={`btn btn-modal btn-white mr-1`}
        >
          {DICT.BTN_CLOSE[LANG]}
        </button>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        closeModal={closeModalShare}
        btnFocusRef={btnModalFocusRef}
        classes="z-40"
        backdropClose={true}
        component={
          <ModalEditText
            closeModal={closeModalShare}
            submitModal={submitModal}
            btnFocusRef={btnModalFocusRef}
            modalData={modalData}
          />
        }
      />
    </div>
  );
}

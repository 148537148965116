import React from 'react'
import { IconLoader2 } from "@tabler/icons";

export default function Loader({ size = 36 }) {
  return (
    <>
      <IconLoader2
        size={size}
        stroke={2}
        strokeLinejoin="round"
        className="text-support-normal animate-spin opacity-80"
      />
    </>
  );
}

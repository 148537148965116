import React, { useEffect, useContext, useState, useRef } from "react";
import DataContext from "../../context/DataContext";
import { DICT } from "../../Dictionary";
import bgMode from "../../utils/bgMode";

import { useNavigate } from "react-router-dom";
import Image from "../../utils/Image";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";

export default function Home() {

  const { appVal: {LANG}, setAppVal } = useContext(DataContext);
  const [imgAllLoaded, setImgAllLoaded] = useState(false);

  const navigate = useNavigate();
  const navRef = useRef(navigate);

  useEffect(() => {
    bgMode("light");
    return () => {};
  }, []);

  const goNext = () => {
    navRef.current("/date-of-birth");
    setAppVal((v) => ({
      ...v,
      USER: {
        birth: null,
        death: null,
      },
    }));
  };

  const goDonate = () => {
    navRef.current("/donate");
  };

  const goAbout = () => {
    navRef.current("/about");
  };
  

  return (
    <div className={`p-4 md:p-6 iconic`}>
      <div className="view-top fix-top">
        <div className="text-left text-lg sm:text-xl font-semibold text-support-deep pb-2">
          <span className="mr-3">{DICT.LANG_CHOOSE[LANG]}</span>
          <span>
            <Dropdown
              selected={DICT.LANG_LABEL[LANG]}
              values={[
                {
                  id: "TH",
                  label: DICT.LANG_LABEL.TH,
                  onClick: () => {
                    setAppVal((v) => ({ ...v, LANG: "TH" }));
                  },
                },
                {
                  id: "EN",
                  label: DICT.LANG_LABEL.EN,
                  onClick: () => {
                    setAppVal((v) => ({ ...v, LANG: "EN" }));
                  },
                },
              ]}
            />
          </span>
        </div>
      </div>
      <div className="view-middle view-middle-light flex flex-col lg:flex-row">
        <div className="box">
          <div className="home-cover block mx-auto my-4 mx-auto lg:mx-8 lg:mr-12 w-2/3 md:w-2/4 lg:w-80">
            <div className={imgAllLoaded ? "block" : "hidden"}>
              <Image
                url="./svg/img_home_cover.svg"
                className="block w-full"
                alt="Life Countdown"
                allLoaded={setImgAllLoaded}
              />
            </div>
            <div
              className={`${imgAllLoaded ? "hidden" : "block"}
              h-56 sm:h-72 flex justify-center items-center`}
            >
              <Loader />
            </div>
          </div>
        </div>
        <div className="box">
          <div className="content text-left mb-4 sm:mb-6">
            <h1 className="text-4xl sm:text-5xl font-bold mb-2 sm:mb-3 text-support-dark leading-none">
              Life Countdown
            </h1>
            <p className="text-base sm:text-lg lg:text-xl text-support-deep">
              {DICT.HOME_P_APPDESC[LANG]}
            </p>
          </div>
          <div className="buttons">
            <div className="mb-3">
              <button onClick={goNext} className="btn btn-primary">
                {DICT.BTN_GETSTARTED[LANG]}
              </button>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <button onClick={goDonate} className="btn btn-secondary">
                {DICT.BTN_SUPPORTUS[LANG]}
              </button>
              <button onClick={goAbout} className="btn btn-secondary">
                {DICT.BTN_ABOUTUS[LANG]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

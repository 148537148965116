import React, { useEffect, useState } from "react";

export default function ProgressCandle({ current, full, desktopMode = true }) {
  
  const [fullHeight, setFullHeight] = useState(180);
  const [candleLong, setCandleLong] = useState(180);

  useEffect(() => {
    if (current / full < 0.15) {
      setFullHeight(150);
    }

    const candleHeight = parseInt(
      fullHeight - (current / full) * fullHeight + 20
    );

    if (current / full < 1) {
      setCandleLong(candleHeight);
    } else {
      setCandleLong(20);
    }
  }, [current, full, fullHeight]);

  return (
    <div
      className={`flex flex-1 items-center justify-center px-3 bg-support-dark rounded-md min-h-280 ${
        desktopMode ? "md:rounded-lg md:px-6 md:py-6 md:mx-0 md:min-h-320" : ""
      }`}
    >
      <div
        className={`candle mt-2 ${current / full < 0.15 ? "mt-12" : "mt-5"}`}
      >
        <div className="stick-full w-11" style={{ height: fullHeight + 20 }}>
          <div
            className="stick-progress w-12 transition-all duration-300"
            style={{
              height: candleLong,
            }}
          >
            <div className="top">
              <div className={`fire ${current < full ? "flex" : "hidden"}`}>
                <div className="fire-1"></div>
                <div className="fire-2"></div>
                <div className="fire-3"></div>
                <div className="fire-light"></div>
              </div>
              <div className="wick"></div>
              <div className="waxs">
                <div className="light"></div>
                <div className="wax-1"></div>
                <div className="wax-2"></div>
                <div className="wax-3"></div>
              </div>
              <div className="shadow-candle"></div>
            </div>
          </div>
        </div>
        <div className="base"></div>
      </div>
    </div>
  );
}

import React, { useRef } from "react";
import { userPlaceholder } from "./svgBase64";
import { IconPlus } from "@tabler/icons";

export default function ImageUpload({
  onFileSelect,
  onFileSelectError,
  modalImgBase64,
  className,
}) {
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    // handle validations
    onFileSelect(e.target.files[0]);
  };

  return (
    <div className={`file-uploader ${className}`}>
      <input
        className="hidden"
        ref={fileInput}
        type="file"
        onChange={handleFileInput}
        accept="image/png, image/gif, image/jpeg"
      />
      <button
        type="button"
        onClick={(e) => fileInput.current && fileInput.current.click()}
        className="group btn block mx-auto relative transition"
      >
        <div className="flex w-24 h-24 mx-auto justify-center items-center rounded-full overflow-hidden group-hover:scale-95 transition">
          {modalImgBase64 ? (
            <div
              className="flex justify-center items-center relative w-full h-full rounded-full overflow-hidden bg-cover bg-center bg-opacity-50"
              style={{
                backgroundImage: `url('${modalImgBase64.data}')`,
              }}
            >
              <img
                className="w-auto h-full max-w-none absolute z-20"
                src={modalImgBase64.data}
                alt=""
              />
              <div className="bg-black bg-opacity-20 w-full h-full z-10"></div>
            </div>
          ) : (
            <img src={userPlaceholder} alt="" className="block" />
          )}
        </div>
        <div className="group-hover:border-main-dark absolute z-20 text-support-deep right-0 bottom-0 flex w-10 h-10 -mb-2 -mr-2 justify-center items-center border-4 border-main-normal bg-white rounded-full transition">
          <IconPlus size={24} stroke={4} />
        </div>
      </button>
    </div>
  );
}

import React, { Fragment } from "react";
import { IconChevronDown } from "@tabler/icons";
import { Menu, Transition } from "@headlessui/react";

export default function Dropdown({ values, selected, scrollHeight }) {

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center w-full justify-between rounded-full bg-white border-2 border-main-light px-4 py-1 hover:bg-opacity-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {selected}
          <IconChevronDown
            size={20}
            stroke={4}
            strokeLinejoin="round"
            className="ml-3 -mr-1 text-main-normal"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`absolute z-20 min-w-full w-32 ${scrollHeight || "max-h-60"} left-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto`}>
          <div className="px-1 py-1">
            {values.map((item, index) => {
              return (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <button
                      onClick={item.onClick}
                      className={`${
                        active
                          ? ""
                          : ""
                      } text-support-deep hover:bg-main-bg group hover:text-support-dark flex w-full items-center rounded-md px-2 py-2 md:py-1`}
                    >
                      {item.label}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

import React, { useContext, useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import DataContext from "../../context/DataContext";
import { DICT } from "../../Dictionary";
import checkUserData from "../../utils/checkUserData";

import { useNavigate } from "react-router-dom";
import BtnBack from "../../components/BtnBack";
import ScrollHeight from "../../components/ScrollHeight";
import { IconCheck } from "@tabler/icons";

export default function DeathMonth() {
  const {
    appVal: { LANG, USER },
    setAppVal,
  } = useContext(DataContext);

  const [monthSelected, setMonthSelected] = useState(USER?.death?.month ?? null);

  const navigate = useNavigate();
  const navRef = useRef(navigate);

  const months = Array.from(new Array(12), (val, index) => index);
  const getMonthName = (month, lang) => {
    if (lang === "en") {
      return format(new Date(2000, month, 1), "MMMM");
    }
    if (lang === "th") {
      return format(new Date(2000, month, 1), "MMMM", { locale: th });
    }
  }

  useEffect(() => {
    checkUserData(
      navRef,
      USER.birth &&
        USER.death &&
        USER.death.year
    );
    return () => {};
  }, [USER.birth, USER.death]);

  const selectMonth = (month) => {
    setMonthSelected(month);
  };

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navRef.current(-1);
    } else {
      navRef.current("/", { replace: true });
    }
  };

  const goNext = () => {
    navRef.current("/fill/day");
    setAppVal((v) => ({
      ...v,
      USER: {
        ...v.USER,
        death: { ...v.USER.death, month: monthSelected, day: null },
      },
    }));
  };

  return (
    <div
      className={`flex flex-col absolute md:relative w-full h-full md:p-6 iconic overflow-hidden`}
    >
      <div className="view-top fix-top md:p-0 md:pb-4 p-4 z-10">
        <div className="flex items-center text-lg sm:text-xl font-semibold text-white">
          <BtnBack onClick={goBack} mode="dark" />
        </div>
      </div>
      <div className="view-middle view-middle-dark flex flex-col flex-1 md:overflow-visible relative">
        <ScrollHeight hasBottom={true}>
          {/* Title */}
          <div className="box text-left md:text-center px-4 md:px-0 mb-2 sm:mb-4">
            <h1 className="text-4xl leading-none sm:text-5xl font-bold text-support-dark mb-2">
              {DICT.LABEL_SELECTMONTHDEATH[LANG]}
            </h1>
            <p className="text-xl sm:text-2xl text-main-dark">
              {DICT.LABEL_WHENMONTHDEATH[LANG]}
            </p>
          </div>

          <div
            className="box-scroll px-4 md:px-0 w-full h-full justify-start md:max-h-cal-inside overflow-auto pt-2"
            style={{
              WebkitMaskImage:
                "linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 98%, transparent 100%)",
            }}
          >
            <div className="max-w-lg mx-auto md:text-xl text-lg">
              <div className="grid grid-cols-2 gap-2 mb-2">
                {months.map((month, index) => {
                  return (
                    <button
                      onClick={() => {
                        selectMonth(month);
                      }}
                      className={`btn btn-fill group ${
                        monthSelected === month
                          ? "active bg-support-normal text-white"
                          : "hover:bg-natural-light hover:bg-opacity-50"
                      }`}
                      key={index}
                    >
                      <span className="inline-block px-2 text-right text-natural-dark font-semibold">
                        {(LANG === "TH" && `${getMonthName(month, "th")}`) ||
                          (LANG === "EN" && `${getMonthName(month, "en")}`)}
                      </span>

                      {monthSelected === month && (
                        <IconCheck
                          size={22}
                          stroke={4}
                          strokeLinejoin="round"
                          className={`absolute right-0 mr-3 text-white hidden sm:block`}
                        />
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </ScrollHeight>
      </div>
      <div className="view-bottom bg-white md:rounded-b-2xl p-4 md:p-6 pt-4 md:pt-6">
        <div className="flex flex-col justify-center mx-auto w-full max-w-lg md:w-64">
          <button
            onClick={goNext}
            className={`btn btn-primary ${monthSelected == null && "disabled"}`}
            disabled={monthSelected == null}
          >
            {DICT.BTN_NEXT[LANG]}
          </button>
        </div>
      </div>
    </div>
  );
}

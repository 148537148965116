import React, { useContext, useState, useEffect } from "react";
import { DICT } from "../../Dictionary";
import DataContext from "../../context/DataContext";
import ImageUpload from "../../components/ImageUpload";
import getBase64 from "../../utils/getBase64";
// import { userPlaceholder } from "../../components/svgBase64"

export default function ModalEditText({
  closeModal,
  submitModal,
  btnFocusRef,
  modalData
}) {
  const {
    appVal: { LANG },
  } = useContext(DataContext);

  const [modalSelectedFile, setModalSelectedFile] = useState(null);
  const [modalImgBase64, setModalImgBase64] = useState(modalData.image || null);
  const [modalCaption, setModalCaption] = useState(modalData.caption || "");

  const msgLength = 80;

  const onInputChange = (e) => {
    const { value, maxLength } = e.target;
    const message = value.slice(0, maxLength);
    setModalCaption(message);
  };

  useEffect(() => {
    const fetchData = async () => {
      setModalImgBase64(await getBase64(modalSelectedFile));
    };
    if (modalSelectedFile) {
      fetchData();
    }
  }, [modalSelectedFile, setModalImgBase64]);

  return (
    <div className="p-4 md:p-6">
      <div className="flex justify-between items-baseline mb-4">
        <h2 className="text-2xl sm:text-3xl font-bold text-support-dark">
          {modalData.caption.length === 0 ? (
            <span>
              {DICT.LABEL_ADD[LANG]}
              {LANG === "EN" && " "}
              {DICT.LABEL_MSG[LANG]}
            </span>
          ) : (
            <span>
              {DICT.LABEL_EDIT[LANG]}
              {LANG === "EN" && " "}
              {DICT.LABEL_MSG[LANG]}
            </span>
          )}
        </h2>
      </div>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ImageUpload
            className="mt-4 mb-6"
            modalImgBase64={modalImgBase64}
            onFileSelect={(file) => setModalSelectedFile(file)}
            onFileSelectError={({ error }) => alert(error)}
          />
          <div className="caption mt-4">
            <textarea
              maxLength={msgLength}
              className="block p-2 w-full border border-main-normal outline-none rounded-lg resize-none"
              rows={2}
              value={modalCaption}
              placeholder={`${DICT.LABEL_TYPING[LANG]}${
                LANG === "EN" ? " " : ""
              }${DICT.LABEL_MSG[LANG]}`}
              onChange={(e) => onInputChange(e)}
              ref={btnFocusRef}
            />
            <div className="text-right text-sm mt-1">
              <span className={`${modalCaption.length >= msgLength ? "text-red-500" : ""}`}>
                {modalCaption.length}/{msgLength} {DICT.LABEL_CHAR[LANG]}
              </span>
            </div>
          </div>
        </form>
      </div>
      <div className="flex flex-row-reverse mt-4 sm:px-4">
        <button
          type="button"
          onClick={() =>
            submitModal({
              caption: modalCaption,
              image: modalImgBase64,
            })
          }
          disabled={modalCaption.length === 0}
          className={`btn btn-modal btn-blue ml-1 ${
            modalCaption.length === 0 && "disabled"
          }`}
        >
          {DICT.BTN_OK[LANG]}
        </button>
        <button
          type="button"
          onClick={closeModal}
          className={`btn btn-modal btn-white mr-1`}
        >
          {DICT.BTN_CANCEL[LANG]}
        </button>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "./App.scss";
import DataContext from "./context/DataContext";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Home from "./pages/start/Home";
import DateOfBirth from "./pages/start/DateOfBirth";
import Intro from "./pages/start/Intro";
import Death from "./pages/fill/Death";
import DeathDay from "./pages/fill/DeathDay";
import DeathMonth from "./pages/fill/DeathMonth";
import DeathYear from "./pages/fill/DeathYear";
import DeathTime from "./pages/fill/DeathTime";
import ResultIntro from "./pages/result/ResultIntro";
import ResultShow from "./pages/result/ResultShow";
import About from "./pages/content/About";
import Donate from "./pages/content/Donate";

function App() {

  const location = useLocation();

  const appDefaultValues = {
    LANG: "TH",
    USER: {
      birth: null,
      death: null
    },
  };

  const getInitialState = () => {
    const appValues = localStorage.getItem("appVal");
    return appValues ? JSON.parse(appValues) : appDefaultValues;
  }

  const [appVal, setAppVal] = useState(getInitialState);

  useEffect(() => {
    // fetchApi();
    localStorage.setItem("appVal", JSON.stringify(appVal));
    window.oncontextmenu = function (event) {
      if (event.button !== 2 && !((event.clientX === event.clientY) === 1)) {
        event.preventDefault();
      }
    };
  }, [appVal]);

  return (
    <DataContext.Provider value={{ appVal, setAppVal }}>
      <div className="app">
        <div className="bg-clip bg-dark">
          <div className="top-right fixed w-40 md:w-48 xl:w-56 right-0 top-0">
            <img
              src="/svg/bg_top_light.svg"
              alt=""
              className="absolute mode-light w-full h-auto transition duration-300"
            />
            <img
              src="/svg/bg_top_dark.svg"
              alt=""
              className="absolute mode-dark w-full h-auto transition duration-300"
            />
          </div>
          <div className="bottom-left fixed w-80 md:w-96 xl:w-96 left-0 bottom-0">
            <img
              src="/svg/bg_bottom_light.svg"
              alt=""
              className="absolute mode-light w-full h-auto bottom-0 transition duration-300"
            />
            <img
              src="/svg/bg_bottom_dark.svg"
              alt=""
              className="absolute mode-dark w-full h-auto bottom-0 transition duration-300"
            />
          </div>
        </div>
        <TransitionGroup className="view-transition-wrapper">
          <CSSTransition
            onEnter={() => document.body.classList.add("overflow-hidden")}
            onExited={() => document.body.classList.remove("overflow-hidden")}
            key={location.key}
            classNames="fade"
            timeout={500}
          >
            <div className="view-transition-absolute">
              <div className="view max-w-screen-lg mx-auto relative">
                <Routes location={location}>
                  <Route path="/" element={<Home />} />
                  <Route path="/date-of-birth" element={<DateOfBirth />} />
                  <Route path="/intro" element={<Intro />} />
                  <Route path="/fill" element={<Death />}>
                    <Route path="year" element={<DeathYear />} />
                    <Route path="month" element={<DeathMonth />} />
                    <Route path="day" element={<DeathDay />} />
                    <Route path="time" element={<DeathTime />} />
                  </Route>
                  <Route path="/result" element={<ResultIntro />} />
                  <Route path="/me" element={<ResultShow />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/donate" element={<Donate />} />
                  <Route
                    path="*"
                    element={<Navigate to="/" replace={true} />}
                  />
                </Routes>
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </DataContext.Provider>
  );
}

export default App;

import React, { useContext, useState, useEffect, useRef } from "react";
import { format } from "date-fns";
import DataContext from "../../context/DataContext";
import { DICT } from "../../Dictionary";
import checkUserData from "../../utils/checkUserData";

import { useNavigate } from "react-router-dom";
import BtnBack from "../../components/BtnBack";
import ScrollHeight from "../../components/ScrollHeight";
import { IconCheck } from "@tabler/icons";
import Modal from "../../components/Modal";

import ModalTimePicker from "../../components/ModalTimePicker";

export default function DeathTime() {
  const {
    appVal: { LANG, USER },
    setAppVal,
  } = useContext(DataContext);

  const [timeSelected, setTimeSelected] = useState(USER?.death?.time || null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const navRef = useRef(navigate);

  const realTimeVal = format(new Date(), "H:mm");

  const [modalHour, setModalHour] = useState("00");
  const [modalMinute, setModalMinute] = useState("00");

  const btnFocusRef = useRef();

  useEffect(() => {
    if(checkUserData(
      navRef,
      USER.birth &&
        USER.death &&
        USER.death.year &&
        USER.death.month &&
        USER.death.day
    )) {
      selectTime(USER.death.time || realTimeVal, false);
    };
  }, [USER.birth, USER.death, realTimeVal]);

  const selectTime = (time, selected) => {
    const timeArray = time.replace(/(^:)|(:$)/g, "").split(":");
    setModalHour(timeArray[0] || "0");
    setModalMinute(timeArray[1] || "00");
    if (selected) setTimeSelected(time);
  };

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navRef.current(-1);
    } else {
      navRef.current("/", { replace: true });
    }
  };

  const goNext = () => {
    navRef.current("/result");
    setAppVal((v) => ({
      ...v,
      USER: {
        ...v.USER,
        death: { ...v.USER.death, time: timeSelected },
      },
    }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  }
  
  const submitModal = () => {
    setTimeSelected(`${modalHour}:${modalMinute}`);
    closeModal();
  }
  
  return (
    <div
      className={`flex flex-col absolute md:relative w-full h-full md:p-6 iconic overflow-hidden`}
    >
      <div className="view-top fix-top md:p-0 md:pb-4 p-4 z-10">
        <div className="flex items-center text-lg sm:text-xl font-semibold text-white">
          <BtnBack onClick={goBack} mode="dark" />
        </div>
      </div>
      <div className="view-middle view-middle-dark flex flex-col flex-1 md:overflow-visible relative">
        <ScrollHeight hasBottom={true}>
          {/* Title */}
          <div className="box text-left md:text-center px-4 md:px-0 mb-2 sm:mb-4">
            <h1 className="text-4xl leading-none sm:text-5xl font-bold text-support-dark mb-2">
              {DICT.LABEL_SELECTTIMEDEATH[LANG]}
            </h1>
            <p className="text-xl sm:text-2xl text-main-dark">
              {DICT.LABEL_WHENTIMEDEATH[LANG]}
            </p>
          </div>

          <div
            className="box-scroll px-4 md:px-0 w-full h-full justify-start md:max-h-cal-inside overflow-auto pt-2"
            style={{
              WebkitMaskImage:
                "linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 1) 98%, transparent 100%)",
            }}
          >
            <div className="max-w-lg mx-auto md:text-xl text-lg">
              <div className="grid grid-cols-2 gap-2 mb-2">
                {DICT.ARR_TIMES[LANG].map((t, index) => {
                  return (
                    <button
                      onClick={() => {
                        selectTime(t.time, true);
                      }}
                      className={`btn btn-fill group ${
                        timeSelected === t.time
                          ? "active bg-support-normal text-white"
                          : "hover:bg-natural-light hover:bg-opacity-50"
                      }`}
                      key={index}
                    >
                      <span className="inline-block px-2 text-right text-natural-dark font-semibold">
                        {t.label}
                      </span>

                      {timeSelected === t.time && (
                        <IconCheck
                          size={22}
                          stroke={4}
                          strokeLinejoin="round"
                          className={`absolute right-0 mr-3 text-white hidden sm:block`}
                        />
                      )}
                    </button>
                  );
                })}
              </div>

              {!timeSelected ? (
                // Unpick
                <button
                  onClick={openModal}
                  className={`btn btn-fill mb-2 group hover:bg-natural-light hover:bg-opacity-50`}
                >
                  <span className="inline-block px-2 text-right text-natural-dark font-semibold">
                    {DICT.LABEL_CUSTOMTIME[LANG]}
                  </span>
                </button>
              ) : DICT.ARR_TIMES[LANG].some((item) => {
                  if (item.time === timeSelected) {
                    return true; // return found
                  } else {
                    return false; // return not found
                  }
                }) ? (
                // Picked and same as time-option
                <button
                  onClick={openModal}
                  className={`btn btn-fill mb-2 group hover:bg-natural-light hover:bg-opacity-50`}
                >
                  <span className="inline-block px-2 text-right text-natural-dark font-semibold">
                    {DICT.LABEL_CUSTOMTIME[LANG]}
                  </span>
                </button>
              ) : (
                // Custom time-option
                <button
                  onClick={openModal}
                  className={`btn btn-fill btn-blue mb-2 group hover:bg-natural-light hover:bg-opacity-50`}
                >
                  <div className="flex flex-col text-center px-2 font-semibold">
                    <span className="leading-5">
                      {DICT.LABEL_CUSTOMTIME[LANG]}
                    </span>
                    <span className="text-xl font-medium">{timeSelected}</span>
                  </div>
                </button>
              )}
            </div>
          </div>
        </ScrollHeight>
      </div>
      <div className="view-bottom bg-white md:rounded-b-2xl p-4 md:p-6 pt-4 md:pt-6">
        <div className="flex flex-col justify-center mx-auto w-full max-w-lg md:w-64">
          <button
            onClick={goNext}
            className={`btn btn-primary ${timeSelected == null && "disabled"}`}
            disabled={timeSelected == null}
          >
            {DICT.BTN_NEXT[LANG]}
          </button>
        </div>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        closeModal={closeModal}
        btnFocusRef={btnFocusRef}
        backdropClose={true}
        component={
          <ModalTimePicker
            hour={modalHour}
            minute={modalMinute}
            setHour={setModalHour}
            setMinute={setModalMinute}
            closeModal={closeModal}
            submitModal={submitModal}
            btnFocusRef={btnFocusRef}
          />
        }
      />
    </div>
  );
}

import React, { useContext, useRef, useEffect } from "react";
import DataContext from "../../context/DataContext";
import { DICT } from "../../Dictionary";
import checkUserData from "../../utils/checkUserData";
import bgMode from "../../utils/bgMode";

import { useNavigate } from "react-router-dom";
import BtnBack from "../../components/BtnBack";

export default function Intro() {
  const {
    appVal: { LANG, USER },
    setAppVal,
  } = useContext(DataContext);

  const navigate = useNavigate();
  const navRef = useRef(navigate);

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navRef.current(-1);
    } else {
      navRef.current("/", { replace: true });
    }
  };

  const goNext = () => {
    navRef.current("/fill/year");
    setAppVal((v) => ({
      ...v,
      USER: {
        ...v.USER,
        death: {
          year: null,
          month: null,
          day: null,
          time: null
        },
      },
    }));
  };
  
  useEffect(() => {
    bgMode("light");
    // Redirect when no data
    checkUserData(navRef, USER.birth);
    return () => {};
  }, [navRef, USER.birth]);

  return (
    <div
      className={`flex flex-col absolute md:relative w-full h-full md:p-6 iconic`}
    >
      <div className="view-top fix-top md:p-0 md:pb-4 p-4 z-10">
        <div className="flex items-center text-lg sm:text-xl font-semibold text-support-deep">
          <BtnBack onClick={goBack} mode="light" />
        </div>
      </div>
      <div className="view-middle view-middle-light flex flex-col flex-1 overflow-y-scroll md:overflow-visible relative">
        <div className="absolute-middle absolute sm:relative w-full h-full">
          <div className="inside-middle p-4 md:p-0">
            <div className="box w-full max-w-lg mx-auto md:text-xl text-lg text-support-deep my-2">
              {DICT.MSG_HOMEINTRO[LANG].map((item, index) => {
                return (
                  <p className="my-1" key={index}>
                    {item}
                  </p>
                );
              })}
            </div>

            <div className="box">
              <div className="flex flex-col justify-center mt-4 pb-4 mx-auto w-full max-w-lg md:w-64">
                <button onClick={goNext} className={`btn btn-primary`}>
                  {DICT.BTN_NEXT[LANG]}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext } from "react";
import TimePicker from "./TimePicker";
import { DICT } from "../Dictionary";
import DataContext from "../context/DataContext";

export default function ModalTimePicker({
  hour,
  minute,
  setHour,
  setMinute,
  closeModal,
  submitModal,
  btnFocusRef
}) {
  const {
    appVal: { LANG },
  } = useContext(DataContext);

  const onChangePicker = (set, value) => {
    const val = value.replace(/\D/g, "");
    if (val === "") {
      set("");
    } else {
      set(val);
    }
  };

  const changeHour = (e) => {
    onChangePicker(setHour, e.target.value);
  };

  const changeMinute = (e) => {
    onChangePicker(setMinute, e.target.value);
  };

  return (
    <div className="p-4 md:p-6">
      <h2 className="text-2xl leading-none sm:text-3xl font-bold text-support-dark mb-2">
        {DICT.LABEL_CUSTOMTIME[LANG]}
      </h2>
      <div className="flex flex-row items-center w-3/4 sm:w-56 mx-auto py-4">
        <TimePicker
          value={hour}
          onChange={changeHour}
          setData={setHour}
          maxNum={23}
          showZero={false}
          loop={true}
        />
        <div className="text-2xl text-gray-400">:</div>
        <TimePicker
          value={minute}
          onChange={changeMinute}
          setData={setMinute}
          maxNum={59}
          showZero={true}
          loop={true}
        />
      </div>
      <div className="flex flex-row-reverse mt-4 sm:px-4">
        <button
          type="button"
          onClick={submitModal}
          className={`btn btn-modal btn-blue ml-1`}
          ref={btnFocusRef}
        >
          {DICT.BTN_OK[LANG]}
        </button>
        <button
          type="button"
          onClick={closeModal}
          className={`btn btn-modal btn-white mr-1`}
        >
          {DICT.BTN_CANCEL[LANG]}
        </button>
      </div>
    </div>
  );
}

export default function bgMode(mode) {
  if (mode === "light") {
    document.body.classList.add("bg-light");
    document.body.classList.remove("bg-dark");
  }
  if (mode === "dark") {
    document.body.classList.add("bg-dark");
    document.body.classList.remove("bg-light");
  }
}

import React, { useContext, useRef, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { intervalToDuration, differenceInDays, differenceInSeconds } from "date-fns";
import DataContext from "../../context/DataContext";
import { DICT } from "../../Dictionary";
import checkUserData from "../../utils/checkUserData";
import bgMode from "../../utils/bgMode";

import { useNavigate } from "react-router-dom";
import { IconExternalLink, IconArrowBackUp, IconHourglassHigh } from "@tabler/icons";
import ProgressArch from "../../components/ProgressArch";
import BtnChart from "../../components/BtnChart";
import ProgressBlock from "../../components/ProgressBlock";
import ProgressCandle from "../../components/ProgressCandle";

import Modal from "../../components/Modal";
import ModalShare from "./ModalShare";

export default function ResultShow() {
  const {
    appVal: { LANG, USER }
  } = useContext(DataContext);

  const navigate = useNavigate();
  
  const navRef = useRef(navigate);
  const timeIntervalRef = useRef(null);

  const [animated, setAnimated] = useState(true);
  const [chartType, setChartType] = useState(0);

  const [distanceDayDead, setDistanceDayDead] = useState(null);
  const [distanceTimeDead, setDistanceTimeDead] = useState(null);
  const [distanceSecondDead, setDistanceSecondDead] = useState(null);

  const [bornToNow, setBornToNow] = useState(null);
  const [bornToDead, setBornToDead] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const btnFocusRef = useRef();

  const goAbout = () => {
    navRef.current("/about");
  };

  const goDonate = () => {
    navRef.current("/donate");
  };
  
  const goStart = () => {
    navRef.current("/");
  };
  
  const changeChart = (id) => {
    setAnimated(false);
    setChartType(id);
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    bgMode("light");
    // Redirect when no data

    const checkDateDate = checkUserData(
      navRef,
      USER.birth &&
        USER.death &&
        USER.death.year &&
        USER.death.month &&
        USER.death.day
    );

    if (checkDateDate) {

      const timeArray = USER.death.time.replace(/(^:)|(:$)/g, "").split(":");
      const hour = timeArray[0] || 0;
      const minute = timeArray[1] || 0;

      const birthDate = new Date(
        USER.birth.year,
        USER.birth.month,
        USER.birth.day
      );

      const deathDate = new Date(
        USER.death.year,
        USER.death.month,
        USER.death.day,
        hour,
        minute
      );

      const timerEvery1Sec = () => {
        setDistanceSecondDead(differenceInSeconds(new Date(), deathDate));
        setDistanceDayDead(differenceInDays(new Date(), deathDate));
        if (deathDate.getTime() > new Date().getTime()) {
          setDistanceTimeDead(intervalToDuration({
            start: new Date(),
            end: deathDate,
          }));
        } else {
          setDistanceTimeDead(null);
        }
      }

      timeIntervalRef.current = setInterval(() => {
        timerEvery1Sec();
      }, 1000);

      timerEvery1Sec();
      setBornToNow(Math.abs(differenceInDays(birthDate, new Date())));
      setBornToDead(Math.abs(differenceInDays(birthDate, deathDate)));

    }

    return () => {
      clearInterval(timeIntervalRef.current);
    };
  }, [navRef, USER.birth, USER.death]);

  return (
    <div
      className={`flex flex-col absolute md:relative w-full h-full md:p-6 iconic`}
    >
      <div className="view-top fix-top md:p-0 md:pb-3 pt-2 pb-2 md:pt-4 md:pb-0 px-4 z-10 shadow-md md:shadow-none">
        <div className="block text-left text-lg sm:text-xl font-bold text-support-dark">
          <h1 className="flex text-xl md:text-2xl py-1 md:py-0 w-full">
            <span className="flex items-center justify-center w-8 h-8 mr-2 rounded-lg bg-support-dark text-white">
              <IconHourglassHigh size={24} stroke={2} />
            </span>
            <span className="flex items-center justify-center ml-0.5">
              Life Countdown
            </span>
          </h1>
        </div>
      </div>
      <div className="view-middle view-middle-result flex flex-col flex-1 overflow-y-scroll md:overflow-visible relative">
        <div className="absolute-middle absolute sm:relative w-full h-full">
          <div className="inside-middle md:p-0">
            <div className="result-box bg-main-bg md:border-2 md:border-main-light md:rounded-2xl p-4 pb-0 md:pb-4 md:px-4 lg:px-6 flex flex-col md:flex-row">
              <div className="box flex flex-none md:w-80 lg:w-96">
                {distanceSecondDead < 0 ? (
                  // Not dead
                  <div className="box-timeleft">
                    <h2 className="text-2xl text-main-dark font-semibold mb-1 text-left md:text-center">
                      {DICT.LABEL_TIMELEFT[LANG]}
                    </h2>
                    <p className="text-5xl md:text-5xl lg:text-6xl text-support-dark font-bold md:mt-2 md:mb-3 text-left md:text-center">
                      <span>
                        <NumberFormat
                          value={distanceDayDead}
                          displayType={"text"}
                          thousandSeparator={true}
                          allowNegative={false}
                        />{" "}
                        {DICT.LABEL_UNITDAY[LANG]}
                      </span>
                    </p>
                    <p className="text-xl md:text-xl lg:text-2xl text-support-deep mt-2 text-left md:text-center">
                      <span>
                        {`${
                          // Hour
                          distanceTimeDead && distanceTimeDead?.hours !== 0
                            ? `${distanceTimeDead?.hours} ${DICT.LABEL_UNITHOUR[LANG]} `
                            : ``
                        }`}
                        {`${
                          // Min
                          distanceTimeDead && distanceTimeDead?.minutes !== 0
                            ? `${distanceTimeDead?.minutes} ${DICT.LABEL_UNITMINUTE[LANG]} `
                            : ``
                        }`}
                        {`${
                          // Sec
                          distanceTimeDead && distanceTimeDead?.seconds !== 0
                            ? `${distanceTimeDead?.seconds} ${DICT.LABEL_UNITSECOND[LANG]}`
                            : ``
                        }`}
                      </span>
                    </p>
                  </div>
                ) : (
                  // Dead
                  <div className="box-notime">
                    <h2 className="text-4xl text-support-dark font-semibold mb-1 text-left md:text-center">
                      {DICT.LABEL_NOTIMELEFT[LANG]}
                    </h2>
                  </div>
                )}
              </div>
              <div className="box flex flex-1 md:mx-4 lg:mx-0">
                <div className="mt-4 md:mt-0 border-2 border-natural-alpha rounded-t-2xl md:border-0">
                  <div className="buttons m-3 justify-end md:justify-center flex flex-row">
                    <BtnChart
                      id={0}
                      changeChart={changeChart}
                      selected={chartType}
                      url="./svg/ic_chart_1"
                    />
                    <BtnChart
                      id={1}
                      changeChart={changeChart}
                      selected={chartType}
                      url="./svg/ic_chart_2"
                    />
                    <BtnChart
                      id={2}
                      changeChart={changeChart}
                      selected={chartType}
                      url="./svg/ic_chart_3"
                    />
                  </div>
                  <div className="chart">
                    {bornToNow && bornToDead && chartType === 0 && (
                      <div className="relative pt-3 mb-3 mx-auto w-full h-full max-w-md lg:max-w-md">
                        <ProgressArch
                          animation={animated}
                          current={bornToNow}
                          full={bornToDead}
                        />
                      </div>
                    )}
                    {bornToNow && bornToDead && chartType === 1 && (
                      <div className="relative mb-3 mx-auto w-full h-full max-w-md lg:max-w-lg">
                        <ProgressBlock current={bornToNow} full={bornToDead} />
                      </div>
                    )}
                    {bornToNow && bornToDead && chartType === 2 && (
                      <div className="relative mb-3 mx-auto w-full h-full max-w-md lg:max-w-lg">
                        <div className="px-3 md:px-0">
                          <ProgressCandle
                            current={bornToNow}
                            full={bornToDead}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="md:mt-3 px-4 md:px-0 flex flex-col md:flex-row-reverse md:justify-between text-lg">
              <div className="buttons-right font-semibold flex mb-6 md:mb-0 bg-natural-alpha md:bg-transparent rounded-b-2xl md:rounded-none">
                <button
                  onClick={openModal}
                  type="button"
                  className="relative flex flex-1 md:border-2 md:border-main-light md:hover:border-main-normal md:hover:bg-main-bg rounded-l-2xl bg-transparent md:bg-white md:flex-none justify-center items-center text-xl bg-white py-2 px-1 md:px-5 mb-0.5 md:mb-0 md:hover:z-10"
                >
                  <IconExternalLink
                    size={24}
                    stroke={2}
                    className="md:text-main-dark text-support-dark mr-1"
                  />
                  <span className="text-support-dark">
                    {DICT.BTN_SHARE[LANG]}
                  </span>
                </button>
                <button
                  type="button"
                  onClick={goStart}
                  className="relative flex flex-1 md:-ml-0.5 md:border-2 md:border-main-light md:hover:border-main-normal md:hover:bg-main-bg rounded-r-2xl bg-transparent md:bg-white md:flex-none justify-center items-center text-xl bg-white py-2 px-1 md:px-5 mb-0.5 md:mb-0"
                >
                  <IconArrowBackUp
                    size={24}
                    stroke={2}
                    className="mt-0.5 md:text-main-dark text-support-dark mr-1 md:-ml-1"
                  />
                  <span className="text-support-dark">
                    {DICT.BTN_STARTAGAIN[LANG]}
                  </span>
                </button>
              </div>
              <div className="buttons-left font-semibold flex flex-row mb-6 md:mb-0">
                <button
                  type="button"
                  className="btn btn-secondary flex-1 bg-white mr-1 md:mr-2 p-1"
                  onClick={goDonate}
                >
                  <span>{DICT.BTN_SUPPORTUS[LANG]}</span>
                </button>
                <button
                  type="button"
                  className="btn btn-secondary flex-1 bg-white ml-1 md:ml-0 p-1"
                  onClick={goAbout}
                >
                  <span>{DICT.BTN_ABOUTUS[LANG]}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        closeModal={closeModal}
        btnFocusRef={btnFocusRef}
        component={
          <div>
            <ModalShare
              chartType={chartType}
              bornToNow={bornToNow}
              bornToDead={bornToDead}
              distanceDayDead={distanceDayDead}
              distanceTimeDead={distanceTimeDead}
              btnFocusRef={btnFocusRef}
              closeModal={closeModal}
            />
          </div>
        }
      />
    </div>
  );
}

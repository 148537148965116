import React, { useContext, useEffect, useState } from "react";
import { addDays, subDays, differenceInYears } from "date-fns";
import DataContext from "../context/DataContext";
import { DICT } from "../Dictionary";
import { IconChevronRight, IconCircleDot } from "@tabler/icons";

export const Block = ({ index, classes, children }) => (
  <div
    key={index}
    className={`items-center justify-center ${classes}`}
  >
    {children}
  </div>
);

export default function ProgressBlock({ current, full, desktopMode = true }) {
  const {
    appVal: { LANG },
  } = useContext(DataContext);

  const [ageCurrent, setAgeCurrent] = useState(null);
  const [ageDeath, setAgeDeath] = useState(null);

  const [blocks, setBlocks] = useState(null);

  useEffect(() => {
    const bornDate = subDays(new Date(), current);
    const deathDate = addDays(new Date(), full - current);

    const diffBornCurrent = differenceInYears(new Date(), bornDate);
    const diffDeathCurrent = differenceInYears(deathDate, bornDate);

    setAgeCurrent(diffBornCurrent);
    setAgeDeath(diffDeathCurrent);

    setBlocks(
      Array.from(new Array(diffDeathCurrent + 1), (val, index) => index)
    );
  }, [current, full]);

  return (
    <div
      className={`flex flex-1 justify-center items-center rounded-md min-h-280 ${
        desktopMode
          ? "md:bg-white md:px-6 md:py-3 md:bg-opacity-50 md:rounded-lg md:min-h-320 px-3"
          : ""
      }`}
    >
      {/* Wrap */}
      <div
        className={`${
          blocks && blocks.length <= 12
            ? "flex flex-wrap justify-center"
            : blocks && blocks.length >= 66
            ? "grid grid-cols-12 w-full gap-1"
            : desktopMode
            ? "grid grid-cols-10 w-full gap-1 sm:grid-cols-10 md:grid-cols-10 lg:grid-cols-12 md:gap-2"
            : "grid grid-cols-10 w-full gap-1"
        } `}
      >
        {/* Born Block */}
        <div
          className={`${
            blocks && blocks.length <= 12
              ? "inline-flex w-full m-1"
              : blocks && blocks.length >= 66
              ? `${desktopMode ? "h-5 md:h-6" : "h-5"} flex w-full rounded-md`
              : "h-7 flex w-full rounded-md md:rounded-lg"
          } col-span-2 flex items-center justify-center bg-main-dark text-white rounded-md md:rounded-lg h-7 font-semibold`}
        >
          <span className={desktopMode ? "" : "-mt-0.5"}>
            {DICT.LABEL_BORN[LANG]}
          </span>
        </div>

        {/* Array Blocks */}

        {blocks &&
          blocks.map((blockNum, index) => {
            // return <div>{blockNum}{ageCurrent}</div>;
            if (blockNum < ageCurrent) {
              return (
                <Block
                  classes={`${
                    blocks && blocks.length <= 12
                      ? "inline-flex w-7 h-7 m-1 rounded-md"
                      : blocks && blocks.length >= 66
                      ? `${
                          desktopMode ? "h-5 md:h-6" : "h-5"
                        } flex w-full rounded-md`
                      : "h-7 flex w-full rounded-md md:rounded-lg"
                  } bg-main-normal`}
                  key={index}
                />
              );
            }

            if (blockNum === ageCurrent) {
              return (
                <Block
                  classes={`${
                    blocks && blocks.length <= 12
                      ? "inline-flex w-7 h-7 m-1 rounded-md"
                      : blocks && blocks.length >= 66
                      ? `${
                          desktopMode ? "h-5 md:h-6" : "h-5"
                        } flex w-full rounded-md`
                      : "h-7 flex w-full rounded-md md:rounded-lg"
                  } bg-support-dark text-main-normal`}
                  key={index}
                >
                  {ageCurrent < ageDeath ? (
                    <div style={{ paddingLeft: 1 }}>
                      <IconChevronRight
                        size={desktopMode ? 24 : 22}
                        stroke={4}
                        className={`${
                          desktopMode ? "pl-0.5 animate-pulse" : ""
                        }`}
                      />
                    </div>
                  ) : (
                    <IconCircleDot size={20} stroke={4} className="" />
                  )}
                </Block>
              );
            }

            if (blockNum > ageCurrent && ageCurrent < ageDeath) {
              return (
                <Block
                  classes={`${
                    blocks && blocks.length <= 12
                      ? "inline-flex w-7 h-7 m-1 rounded-md"
                      : blocks && blocks.length >= 66
                      ? `${
                          desktopMode ? "h-5 md:h-6" : "h-5"
                        } flex w-full rounded-md`
                      : "h-7 flex w-full rounded-md md:rounded-lg"
                  } bg-support-normal opacity-30`}
                  key={index}
                />
              );
            }

            return false;
          })}

        {/* Death Block */}

        <div
          className={`${
            blocks && blocks.length <= 12
              ? "inline-flex w-full m-1"
              : blocks && blocks.length >= 66
              ? `${desktopMode ? "h-5 md:h-6" : "h-5"} flex w-full rounded-md`
              : "h-7 flex w-full rounded-md md:rounded-lg"
          } col-span-2 flex items-center justify-center bg-support-normal text-white rounded-md md:rounded-lg h-7 font-semibold`}
        >
          <span className={desktopMode ? "" : "-mt-1"}>
            {DICT.LABEL_DEATH[LANG]}
          </span>
        </div>
      </div>
    </div>
  );
}

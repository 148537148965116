import React, { useContext } from "react";
import * as d3 from "d3";
import { useD3 } from "../utils/useD3";
import { roundedRect } from "../utils/roundedRect";
import { semiboldBase64, extraboldBase64 } from "../styles/fonts/font-base64";
import { bornBase64, deadBase64 } from "./svgBase64";
import DataContext from "../context/DataContext";
import { DICT } from "../Dictionary";

export default function ProgressArch({ animation, current, full }) {

  const {
    appVal: { LANG }
  } = useContext(DataContext);

  const formatNum = (value, dp) => {
    return +parseFloat(value).toFixed(dp);
  };

  const ref = useD3((svg) => {
    const w = 300,
      h = 220;

    const outerRadius = w / 2 - 20;
    const innerRadius = 75;

    const color = ["#2E529C", "#FFA02F", "#0F2542", "#222222"];

    const arc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(0)
      .endAngle(Math.PI);

    const arcLine = d3
      .arc()
      .innerRadius(75)
      .outerRadius(76.5)
      .startAngle(0)
      .endAngle(Math.PI);

    const arcOutLine = d3
      .arc()
      .innerRadius(136)
      .outerRadius(136)
      .startAngle(0)
      .endAngle(Math.PI);

    const arcProgress = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .startAngle(0);

    svg.attr("viewBox", `0 0 ${w} ${h}`);
    // .attr("width", w)
    // .attr("height", h)

    svg
      .select(".chart-area")
      .attr(
        "transform",
        "translate(" + w / 2 + "," + (h / 2 + w / 4 - 37.5) + ")"
      );

    svg
      .select(".all")
      .datum({ endAngle: 0 })
      .attr("d", arc)
      .attr("transform", "rotate(-90)")
      .style("fill", color[0])
      .style("fill-opacity", "20%");

    const progressLine = svg
      .select(".progressLine")
      .datum({ endAngle: 0 })
      .attr("d", arcProgress)
      .attr("transform", "rotate(-90)")
      .style("fill", function (d, i) {
        return color[1];
      });

    const pointer = svg
      .select(".pointer")
      .datum({ endAngle: 0 })
      .attr("x1", -140)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", 0);

    svg
      .select(".pointerLine")
      .datum({ endAngle: 0 })
      // .attr("d", arcProgress)
      .attr("stroke-width", 1.5)
      .attr("stroke-linecap", "round")
      .attr("stroke", color[2])
      .attr("x1", -136)
      .attr("y1", 0)
      .attr("x2", -76)
      .attr("y2", 0);

    svg.select(".dotPointer").attr("transform", "translate(-136, 0)");

    svg
      .select(".dotPointerOut")
      .attr("r", 8)
      .attr("cy", 0)
      .attr("cx", 0)
      .attr("stroke-width", 1.5)
      .attr("stroke-linecap", "round")
      .attr("stroke", color[2])
      .style("fill", d3.rgb("#ffffff"));

    svg
      .select(".dotPointerIn")
      .attr("r", 5)
      .attr("cy", 0)
      .attr("cx", 0)
      .style("fill", color[1]);

    svg
      .select(".all-out-stroke")
      .datum({ endAngle: 0 })
      .attr("d", arcOutLine)
      .attr("transform", "rotate(-90)")
      .attr("stroke-dasharray", "1 6")
      .attr("stroke-width", 1.5)
      .attr("stroke-linecap", "round")
      .attr("stroke", color[2])
      .style("fill", "none");

    svg
      .select(".all-stroke")
      .datum({ endAngle: 0 })
      .attr("d", arcLine)
      .attr("transform", "rotate(-90)")
      .style("fill", color[2]);

    svg
      .select(".middleLabel")
      .attr("text-anchor", "middle")
      .attr("dy", -38)
      .attr("dx", 0)
      .style("fill", color[2])
      .style("font-family", "'fc_iconic_d3', sans-serif")
      .style("font-size", "16px")
      .style("font-weight", "normal");

    svg.select(".badgeLeft").attr("transform", "translate(-75, 0)");
    svg.select(".badgeRight").attr("transform", "translate(75, 0)");

    svg
      .select(".lineLeft")
      .attr("stroke-width", 1.5)
      .attr("stroke-linecap", "round")
      .attr("stroke", color[2])
      .attr("x1", -0.75)
      .attr("y1", 0)
      .attr("x2", -65)
      .attr("y2", 0);

    svg
      .select(".lineRight")
      .attr("stroke-width", 1.5)
      .attr("stroke-linecap", "round")
      .attr("stroke", color[2])
      .attr("x1", 0.75)
      .attr("y1", 0)
      .attr("x2", 65)
      .attr("y2", 0);

    svg
      .select(".rectLeft")
      .attr("d", roundedRect(0, 0, 55, 55, 20, 0, 0, 20, 20))
      .attr("fill", color[1])
      .attr("fill-opacity", "50%")
      .attr("transform", "translate(-55, 0)");

    svg
      .select(".rectRight")
      .attr("d", roundedRect(0, 0, 55, 55, 20, 0, 0, 20, 20))
      .attr("fill", color[0])
      .attr("fill-opacity", "20%");

    svg
      .select(".imgBorn")
      .attr("xlink:href", bornBase64)
      .attr("width", 42)
      .attr("height", 42)
      .attr("transform", "translate(-48, 6)");

    svg
      .select(".imgDead")
      .attr("xlink:href", deadBase64)
      .attr("width", 42)
      .attr("height", 42)
      .attr("transform", "translate(7, 6)");

    svg
      .select(".labelLeft")
      .attr("transform", "translate(10, 32)")
      .style("fill", color[2])
      .style("font-family", "'fc_iconic_d3', sans-serif")
      .style("font-size", "18px")
      .style("font-weight", "normal");

    svg
      .select(".labelRight")
      .attr("transform", "translate(-10, 32)")
      .style("fill", color[2])
      .style("text-anchor", "end")
      .style("font-family", "'fc_iconic_d3', sans-serif")
      .style("font-size", "18px")
      .style("font-weight", "normal");

    const middleCount = svg
      .select(".middleCount")
      .datum(0)
      .text(function (d) {
        return d;
      })
      .attr("text-anchor", "middle")
      .attr("dy", 0)
      .attr("dx", 0)
      .style("fill", color[2])
      .style("font-family", "'fc_iconic_d3', sans-serif")
      .style("font-size", "38px")
      .style("font-weight", "bold");

    const oldValue = 0;

    const arcTween = function (transition, newValue, oldValue) {
      transition.attrTween("d", function (d) {
        const interpolate = d3.interpolate(
          d.endAngle,
          Math.PI * (newValue / 100)
        );

        const interpolateCount = d3.interpolate(oldValue, newValue);

        return function (t) {
          d.endAngle = interpolate(t);
          pointer.attr(
            "transform",
            `rotate(${parseFloat(interpolate(t) * 57.2)})`
          );
          middleCount.text(formatNum(interpolateCount(t), 1) + "%");
          return arcProgress(d);
        };
      });
    };

    const progressData = () => {
      const progress = (current / full) * 100;
      if (progress > 99.5 && progress < 100) {
        return progress - (progress - 99.5) / 5;
      } else if (progress <= 99.5) {
        return progress;
      } else {
        return 100;
      }
    }

    progressLine
      .transition()
      .delay(animation ? 300 : 0)
      .duration(animation ? 1500 : 0)
      .ease(d3.easePolyInOut)
      .call(arcTween, progressData(), oldValue);
  }, []);

  return (
    <div className="w-full h-full">
      <svg ref={ref}>
        <defs>
          <style>
            {`@font-face {font-family: "fc_iconic_d3"; src: url(${semiboldBase64}) format('woff2'); font-weight: normal; font-style: normal;} `}
            {`@font-face {font-family: "fc_iconic_d3"; src: url(${extraboldBase64}) format('woff2'); font-weight: bold; font-style: normal;} `}
          </style>
        </defs>
        <g className="chart-area" shapeRendering="optimizeQuality">
          <path className="all" />
          <path className="progressLine" />
          <path className="all-out-stroke" shapeRendering="optimizeQuality" />
          <path className="all-stroke" shapeRendering="optimizeQuality" />

          <g className="badgeLeft">
            <path className="rectLeft" />
            <image className="imgBorn" />
            <line className="lineLeft" />
            <text className="labelLeft">{DICT.LABEL_BORN[LANG]}</text>
          </g>
          <g className="badgeRight">
            <path className="rectRight" />
            <image className="imgDead" />
            <line className="lineRight" />
            <text className="labelRight">{DICT.LABEL_DEATH[LANG]}</text>
          </g>

          <g className="pointer">
            <line className="pointerLine" />
            <g className="dotPointer">
              <circle className="dotPointerOut" />
              <circle className="dotPointerIn" />
            </g>
          </g>

          <g>
            <text className="middleLabel">{DICT.LABEL_PASS[LANG]}</text>
            <text className="middleCount" />
          </g>
        </g>
      </svg>
    </div>
  );
}

import html2canvas from "html2canvas";

const imageUrl = async (el) => {

  function svgToCanvas(targetElem) {
    var svgElem = targetElem.getElementsByTagName("svg");
    for (const node of svgElem) {
      node.setAttribute(
        "font-family",
        window.getComputedStyle(node, null).getPropertyValue("font-family")
      );
      node.replaceWith(node);
    }
  }

  svgToCanvas(el);

  const canvas = await html2canvas(el, {
    scale: 2,
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
    windowWidth: el.scrollWidth,
    windowHeight: el.scrollHeight,
    onclone: function (clonedDoc) {
      clonedDoc.getElementById("captureImg").style.display = "block";
    },
  });
  const image = canvas.toDataURL("image/png", 1.0);
  // document.body.appendChild(canvas);
  return image;
};

const exportAsImage = async (image, imageFileName) => {
  const imgUrl = await imageUrl(image, imageFileName);
  // downloadImage(imgUrl, imageFileName);
  return imgUrl;
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);
  fakeLink.remove();
};

export { exportAsImage, imageUrl, downloadImage };
